/* eslint-disable no-undef */
import React, { useEffect, useContext } from 'react';
import Resultado from './resultados';
import Exame from './exame';
import Paciente from './paciente';
import Imagens from './imagens';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { saveAs } from 'file-saver';
import {
    SttSelect,
    SttMenuItem,
    SttContainer,
    SttHeader,
    SttMainNavigationBar,
    SttBox,
    SttGrid,
    SttButton,
    SttSplashScreen,
    SttTranslateHook
} from '@stt-componentes/core';
import { SITUACAO, DESCRICAO_TIPO_MODALIDADE_IMPRESSAO_RADIOLOGIA, MODALIDADES_TELEDIAGNOSTICO, CONSTANTES_MODALIDADES, SIM } from '../../constantes';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { verificaPossibilidadeImpressaoImagens, baixarArquivo } from '../../util';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { carregando, dadosExame, telaProtocolo, menuSelecionado, exameSelecionado } from '../../signals';
import alerta from '../../signals/alerta';

const useStyles = makeStyles(theme => ({
    seletorExames: {
        width: '30%'
    },
    containerSeletorExames: {
        paddingTop: theme.spacing(2)
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 4, 2, 4)
    },
    gridBotoes: {
        marginTop: theme.spacing(1)
    }
}));

const TelaInterno = () => {
    useSignals();
    const navigate = useNavigate();
    const classes = useStyles();
    const isMobileOrTablet = useMediaQuery({ maxWidth: 814 });
    const isDesktop = useMediaQuery({ minWidth: 815 });
    const listaExames = [];
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const openSS = useSignal(false);
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

    const submenus = [
        {
            text: strings.paciente,
            button: true,
            onClick: () => menuSelecionado.value = SITUACAO.PACIENTE
        },
        {
            text: strings.exame,
            button: true,
            onClick: () => menuSelecionado.value = SITUACAO.EXAME
        },
        {
            text: strings.imagens,
            button: true,
            onClick: () => menuSelecionado.value = SITUACAO.IMAGENS
        },
        {
            text: strings.resultado,
            button: true,
            onClick: () => menuSelecionado.value = SITUACAO.RESULTADO
        }
    ];

    if (dadosExame.value?.length > 1) {
        dadosExame.value.forEach((_, index) => {
            let tituloExame = `${strings.tituloExame} ${index + 1}`;
            if (exameSelecionado.value == index) {
                tituloExame = strings.tituloExameAtual;
            }

            listaExames.push({
                key: index,
                text: tituloExame,
                onClick: () => {
                    exameSelecionado.value = index;
                },
                button: true
            });
        });

        submenus.push({
            text: strings.tituloOutrosExames,
            button: true,
            list: listaExames
        });
    }

    useEffect(() => {
        menuSelecionado.value = SITUACAO.EXAME;

        carregando.value = {
            ...carregando.value,
            open: false
        };
    }, [exameSelecionado.value, dadosExame.value]);

    const handleVoltarTelaProtocolo = () => {
        telaProtocolo.value = true;
        menuSelecionado.value = SITUACAO.EXAME;
        carregando.value = {
            ...carregando.value,
            open: false
        };
        navigate('/');
    };

    const BotaoImpressao = () => {
        return (
            <SttButton
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                    const exameImpressao = dadosExame.value[exameSelecionado.value];
                    if (exameImpressao.exame.imprime_exame === SIM) {
                        if (MODALIDADES_TELEDIAGNOSTICO.includes(exameImpressao.exame.sigla_modalidade)) {
                            imprimir(exameImpressao.exame.id, exameImpressao.exame.sigla_modalidade, exameImpressao.exame.descricao, verificaPossibilidadeImpressaoImagens(exameImpressao.exame.sigla_modalidade));
                            return;
                        }

                        alerta.value = {
                            open: true,
                            title: strings.atencao,
                            message: strings.mensagemImprimirImagensExame,
                            type: 'alert',
                            options: [
                                {
                                    title: strings.sim,
                                    onClick: () => {
                                        imprimir(exameImpressao.exame.id, exameImpressao.exame.sigla_modalidade, exameImpressao.exame.descricao, true);
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        }
                                    }
                                },
                                {
                                    title: strings.nao,
                                    onClick: () => {
                                        imprimir(exameImpressao.exame.id, exameImpressao.exame.sigla_modalidade, exameImpressao.exame.descricao, false);
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        }
                                    }
                                }
                            ],
                            onClose: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    } else {
                        imprimir(exameImpressao.exame.id, exameImpressao.exame.sigla_modalidade, exameImpressao.exame.descricao, false);
                    }
                }}
                nomarginleft="true"
            >
                {strings.imprimir}
            </SttButton>
        );
    };

    const BotaoDownload = () => {
        return (
            dadosExame.value[exameSelecionado.value] &&
                dadosExame.value[exameSelecionado.value].exame &&
                dadosExame.value[exameSelecionado.value].exame.possui_imagens &&
                dadosExame.value[exameSelecionado.value].exame.descricao_tipo_modalidade === DESCRICAO_TIPO_MODALIDADE_IMPRESSAO_RADIOLOGIA ?
                <SttButton
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={download}
                >
                    {strings.download}
                </SttButton> :
                ''
        );
    };

    const download = () => {
        const exame = dadosExame.value[exameSelecionado.value].exame;
        // Para as stacks com pacs novo, download direto com weasis-portable sem passar pelo backend.
        const downloadWeasis = exame.url_download_weasis;
        if (downloadWeasis) {
            baixarArquivo(downloadWeasis.replace('<StudyInstanceUID>', exame.studyinstanceuid).replace('<token>', exame.token_pacs));
            return;
        }
        
        carregando.value = {
            open: true,
            descricao: strings.baixandoEstudoDicom,
        }
        const idExame = exame.id;
        const idCodificado = Buffer.from(`${idExame}`).toString('base64');

        const urlDownload = `${EXAMES_API_BASE_URL}/exame/${idCodificado}/weasis`;
        axios
            .get(urlDownload, { responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    const blob = new Blob([response.data], { type: 'application/zip' });
                    saveAs(blob, `Estudo_${dadosExame.value[exameSelecionado.value].paciente[0].nome.replace(/\W+|\s+/g, '_')}.zip`);
                }
            })
            .catch(err => {
                console.log(err);
                alerta.value = {
                    ...alerta.value,
                    titulo: strings.erro
                }
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    message: strings.erroDownloaEstudoDicom,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    ]
                }
            })
            .finally(() => {
                carregando.value = {
                    ...carregando.value,
                    open: false
                }
            });
    };

    const switchMenuAplicativo = () => {
        switch (menuSelecionado.value) {
            case SITUACAO.RESULTADO:
                return (
                    <Resultado />
                );
            case SITUACAO.EXAME:
                return (
                    <Exame BotaoImpressao={BotaoImpressao} BotaoDownload={BotaoDownload} />
                );
            case SITUACAO.PACIENTE:
                return (
                    <Paciente />
                );
            case SITUACAO.IMAGENS:
                return (
                    <Imagens />
                );
            default:
                return (
                    <Resultado />
                );
        }
    };

    const handleSelectChange = () => event => {
        exameSelecionado.value = event.target.value;
    };

    const imprimir = (idExame, modalidade, descricao, imprimeImagens) => {
        carregando.value = {
            open: true,
            descricao: strings.gerandoDocumento
        }
        const idExameCodificado = Buffer.from(idExame.toString()).toString('base64');
        const opcoes = {
            params: {
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao
            }
        };

        if (imprimeImagens) {
            opcoes.params = { ...opcoes.params, imagens: true }
        }

        axios
            .get(`${EXAMES_API_BASE_URL}/exame/${idExameCodificado}/imprimir`, opcoes)
            .then((response) => {
                window.open(response.data);
                if (!window || window.closed || typeof window.closed == 'undefined') {
                    alerta.value = {
                        open: true,
                        title: strings.popupBloqueado,
                        message: strings.mensagemPopupBloqueado,
                        type: 'alert',
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }
                            }
                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }
                }
            })
            .catch(err => {
                alerta.value = {
                    ...alerta.value,
                    title: strings.erro,
                    type: 'error',
                    message: strings.erroImpressaoExame,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    ],
                    open: true
                }
            })
            .finally(() => {
                carregando.value = {
                    ...carregando.value,
                    open: false
                }
            });
    };

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = true,
                    strings
                }}
                config={global.gConfig}
                redirectUrl={global.gConfig.url_callback}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                open={openSS.value}
                setOpen={(open) => { openSS.value = open }}
            />
            {
                isDesktop &&
                <div>
                    <SttMainNavigationBar
                        titulo={strings.tituloExame}
                        config={global.gConfig}
                        mostrarMenuAplicativos={false}
                    />
                    <SttContainer maxWidth="lg" className={classes.container}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleVoltarTelaProtocolo}
                                    nomarginleft="true"
                                >
                                    {strings.voltar}
                                </SttButton>
                            </SttGrid>
                        </SttGrid>
                    </SttContainer>
                    {isDesktop &&
                        listaExames.length > 1 &&
                        <SttContainer maxWidth="lg" className={classes.containerSeletorExames}>

                            <SttSelect
                                label={strings.tituloOutrosExames}
                                onChange={handleSelectChange()}
                                value={exameSelecionado.value}
                                className={classes.seletorExames}
                            >
                                {listaExames.map(exame => (
                                    <SttMenuItem
                                        key={exame.key}
                                        value={exame.key}
                                    >
                                        {exame.text}
                                    </SttMenuItem>
                                ))}
                            </SttSelect>
                        </SttContainer>}

                    <SttBox>
                        <SttContainer maxWidth="lg" className={classes.container}>
                            <Paciente />
                            <Exame />
                            <Imagens />
                            <Resultado />
                            {

                                (dadosExame.value && dadosExame.value[exameSelecionado.value]) ?

                                    <SttGrid container spacing={3} className={classes.gridBotoes}>
                                        <SttGrid item xs={12}>
                                            <BotaoImpressao />
                                            <BotaoDownload />
                                        </SttGrid>
                                    </SttGrid> :
                                    ''
                            }
                        </SttContainer>
                    </SttBox>
                </div>
            }
            {
                isMobileOrTablet &&
                <div>
                    <SttMainNavigationBar
                        titulo={strings.tituloExame}
                        submenus={submenus}
                        botaoSair={true}
                        handleClickSair={handleVoltarTelaProtocolo}
                    />
                    <SttContainer maxWidth="lg" className={classes.container}>
                        {
                            switchMenuAplicativo()
                        }
                    </SttContainer>
                </div>
            }
        </div>
    );
};

export default TelaInterno;